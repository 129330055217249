<template>
  <div class="random-page">
    <div class="herosection">
      <div class="filigrane-color" />
      <img
        :src="require('@/assets/images/tourismeBenin/musee.jpg')"
        alt=""
        class="herosection-back-image"
      />
      <h1 style="color: #fff; text-transform: uppercase">
        Chiffres & Tendances
      </h1>
      <h3 class="my-2">Guide touristique</h3>
    </div>
    <blog-section sectionName="Règlementations" />
    <!-- <div class="random-page-wrapper">
      <b-card>
        <h2 class="mb-2">Les services dématérialisés</h2>
      </b-card>
    </div> -->
  </div>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue';
import BlogSection from '@/components/HomeComponents/BlogSection.vue';

export default {
  components: {
    BButton,
    BCard,
    BlogSection,
  },
};
</script>

<style scoped>
.random-page {
}
h1,
h3 {
  color: #fff;
}
.random-page-wrapper {
  max-width: 1250px;
  margin: 0 auto;
  padding: 2em;
}
.herosection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;

  padding: 6em 2em;
  /* height: 323px; */
}
.herosection h1 {
  font-size: 27px;
}
.herosection-back-image {
  position: absolute;
  object-fit: cover;
  inset: 0;
  z-index: -20;
  width: 100%;
  height: 100%;
}
.herosection .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  background-color: #06250fb2;
}
.btns-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .herosection h1 {
    font-size: 42px;
  }
  .btns-actions {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
</style>
